export const organization = "aidemec";

// Factory functions for creating config objects
const createStatsConfig = (baseConfig, statsParams, attribute, stat, plotId, includeProductFilter = true) => ({
	...baseConfig,
	type: "stats",
	params: JSON.stringify({
		...statsParams,
		attribute: [attribute],
		stat,
		filters: includeProductFilter ? [baseConfig.productFilter] : [],
		group_by: includeProductFilter ? "key" : undefined,
	}),
	plotId,
});

const aidemecConfigs = (product, startDate, endDate) => {
	// Base configuration
	const baseConfig = {
		project: "aidemecproject",
		collection: "weatherdata",
		productFilter: {
			property_name: "key",
			operator: "eq",
			property_value: product,
		},
	};

	const dateFilter = [
		{
			property_name: "timestamp",
			operator: "gte",
			property_value: startDate,
		},
		{
			property_name: "timestamp",
			operator: "lte",
			property_value: endDate,
		},
	];

	const statsBaseParams = {
		interval: "every_1_months",
		start_time: startDate,
		end_time: endDate,
	};

	return [
		// Metrics data config
		{
			...baseConfig,
			type: "data",
			params: JSON.stringify({
				attributes: [
					"key", "timestamp", "air_temperature_max_c", "air_temperature_min_c", "air_temperature_med_c",
					"rain_mm", "eto_hargreaves_mm",
					"relative_humidity_max_pct", "relative_humidity_min_pct", "relative_humidity_med_pct",
				],
				filters: [baseConfig.productFilter, ...dateFilter],
				order_by: { field: "timestamp", order: "asc" },
			}),
			plotId: "metrics",
		},
		// Stats configs generated by factory function
		createStatsConfig(baseConfig, statsBaseParams, "rain_mm", "sum", "precipitationSum", false),
		createStatsConfig(baseConfig, statsBaseParams, "air_temperature_max_c", "max", "maxTemperature"),
		createStatsConfig(baseConfig, statsBaseParams, "air_temperature_min_c", "min", "minTemperature"),
		createStatsConfig(baseConfig, statsBaseParams, "relative_humidity_med_pct", "avg", "avgHumidity"),
	];
};

export default aidemecConfigs;
