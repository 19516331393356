export const organization = "lofs";

// Factory functions for creating config objects
const createStatsConfig = (baseConfig, statsParams, attribute, stat, plotId, includeProductFilter = true) => ({
	...baseConfig,
	type: "stats",
	params: JSON.stringify({
		...statsParams,
		attribute: [attribute],
		stat,
		filters: includeProductFilter ? [baseConfig.productFilter] : [],
		group_by: includeProductFilter ? "key" : undefined,
	}),
	plotId,
});

const lofsConfigs = (product, startDate, endDate) => {
	// Base configuration
	const baseConfig = {
		project: "lofsproject",
		collection: "weatherdata",
		productFilter: {
			property_name: "key",
			operator: "eq",
			property_value: product,
		},
	};

	const dateFilter = [
		{
			property_name: "timestamp",
			operator: "gte",
			property_value: startDate,
		},
		{
			property_name: "timestamp",
			operator: "lte",
			property_value: endDate,
		},
	];

	const statsBaseParams = {
		interval: "every_1_months",
		start_time: startDate,
		end_time: endDate,
	};

	return [
		// Metrics data config
		{
			...baseConfig,
			type: "data",
			params: JSON.stringify({
				attributes: [
					"key", "timestamp", "temp_max_c", "temp_min_c",
					"rain_mm", "etp_mm", "humidity_percent",
					"co2_ppm", "solar_radiation_j_cm2", "solar_radiation_mj_m2", "wind_speed_2m",
				],
				filters: [baseConfig.productFilter, ...dateFilter],
				order_by: { field: "timestamp", order: "asc" },
			}),
			plotId: "metrics",
		},
		// Stats configs generated by factory function
		createStatsConfig(baseConfig, statsBaseParams, "rain_mm", "sum", "precipitationSum", false),
		createStatsConfig(baseConfig, statsBaseParams, "temp_max_c", "max", "maxTemperature"),
		createStatsConfig(baseConfig, statsBaseParams, "temp_min_c", "min", "minTemperature"),
		createStatsConfig(baseConfig, statsBaseParams, "humidity_percent", "avg", "avgHumidity"),
		createStatsConfig(baseConfig, statsBaseParams, "co2_ppm", "avg", "avgCo2"),
		createStatsConfig(baseConfig, statsBaseParams, "wind_speed_2m", "avg", "avgWindSpeed"),
		createStatsConfig(baseConfig, statsBaseParams, "solar_radiation_mj_m2", "avg", "avgSolarRadiation"),
	];
};

export default lofsConfigs;
